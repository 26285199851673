import { getListingFilters } from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'

export enum ProductAvailability {
  AVAILABLE = 'available',
  SOLD_OUT = 'sold_out',
  ON_REQUEST = 'on_request',
}

export enum ProductFilters {
  Region = 'Region für Filter',
  Origin = 'Herkunft für Filter',
}

export type Filter = typeof getListingFilters & {
  id: string
  description: string
  options: (Schemas['PropertyGroupOption'] & { independent?: boolean })[]
}
